import React from 'react';
const NameCompany = (company) => {
    let obj = {}
    switch(company.toUpperCase()){
        case 'FISA':
            obj = {
                display: "Financiera Independencia",
                legalName: "Financiera Independencia S.A.B de C.V., SOFOM, E.N.R.",
                slogan: <strong>El préstamo fácil  <br/>sin salir de casa</strong>
            }
        break;
        case 'PRESTASUMA':
            obj = {
                display: "Presta Suma",
                legalName: "Financiera Independencia S.A.B de C.V., SOFOM, E.N.R.",
                slogan: <strong>La opción para ti</strong>
            }
        break;
        case 'CREDICONSTRUYE':
            obj = {
                display: "Credi Construye",
                legalName: "Financiera Independencia S.A.B de C.V., SOFOM, E.N.R.",
                slogan: <strong>Creemos en tus metas</strong>
            }
        break;
        case 'PRESTAUNICO':
            obj = {
                display: "Presta Único",
                legalName: "Financiera Independencia S.A.B de C.V., SOFOM, E.N.R.",
                slogan: <strong>Para alcanzar tus sueños</strong>
            }
        break;
        case 'AEF':
            obj = {
                display: "Apoyo Económico",
                legalName: "Apoyo Económico Familiar S.A. de C.V., SOFOM., E.N.R.",
                slogan: <strong>El préstamo fácil  <br/>sin salir de casa</strong>
            }
        break;
        case 'FINDEP':
            obj = {
                display: "Apoyo Económico",
                legalName: "Apoyo Económico Familiar S.A. de C.V., SOFOM., E.N.R.",
                slogan: <strong>El préstamo fácil  <br/>sin salir de casa</strong>
            }
        break;        
        case 'AFI':
            obj = {
                display: "Apoyo Financiero",
                legalName: "Apoyo Financiero, Inc.",
                slogan: <strong>El préstamo fácil  <br/>sin salir de casa</strong>
            }
        break;
        default: 
        obj = {
            slogan: <strong>El préstamo fácil  <br/>sin salir de casa</strong>
        }
    }
    return obj
}

export default NameCompany