import React from 'react';
import { useStaticQuery, graphql } from "gatsby"

const AvisosApp = (props) => {
    const data = useStaticQuery(graphql`
    query {
        allComponentsJson(filter: {component: {eq: "avisos-app"}}) {
            edges {
              node {
                avisos {
                  company
                  avisos
                  display
                  terminos
                }
              }
            }
          }
      }
  `)
    // const url = data.allComponentsJson.edges[0].node.url.find(item => item.company === props.company)
    const avisos = data.allComponentsJson.edges[0].node.avisos.find(item => item.company === props.company)
    return (
        props.terminos 
        ? 
        <a id={props.id} href={avisos.terminos} target="_blank" style={{ color: "#bf0000" }}>{props.children}</a>
        :
        <a id={props.id} href={avisos.avisos} target="_blank" style={{ color: "#bf0000" }}>{props.children}</a>
    );
}

export default AvisosApp;

